<template>
  <div class="video-uploader">
    <div class="section-title-row" v-if="!noTitle">
      <div class="title">{{title}}</div>
      <div class="line"></div>
    </div>
    <el-upload
      class="upload-demo"
      :disabled="disabled"
      :class="{'disabled-upload': disabledUpload || disabled}"
      action="/bengche/common/upload"
      :limit="1"
      :on-preview="handlePreviewAttachment"
      :on-remove="handleRemoveAttachment"
      :on-success="handleSuccess"
      :file-list="videos"
      :headers="header"
    >
      <el-button size="small" type="primary">点击上传视频</el-button>
      <div slot="tip" class="el-upload__tip">只能上传1个mp4文件，且不超过5MB，建议通过app上传</div>
    </el-upload>
    <div class="date-row" v-if="showCreateDate">
      <div
        class="date-info"
        v-for="i in initialVideos"
        :key="i.id"
      >{{i.createTime}}</div>
    </div>
    <el-dialog height="400px" width="400px" :visible.sync="dialogVisible" style="text-align: center;">
      <video height="100%" width="100%" :src="dialogVideoUrl" controls="controls" style="text-align: center;"></video>
    <!-- <div @click="rotota">旋转</div> -->
    </el-dialog>
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  name: 'video-uploader',

  data () {
    return {
      rt: 0,
      dialogVideoUrl: '',
      dialogVisible: false,
      videoCount: 0,
      header: {},
      initialVideos: []
    }
  },

  props: {
    title: String,
    videos: Array,
    noTitle: {
      type: Boolean,
      default: false
    },
    limit: Number,
    disabled: {
      type: Boolean,
      default: false
    },
    showCreateDate: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    videos (v) {
      if (v) {
        this.videoCount = v.length
        this.initialVideos = JSON.parse(JSON.stringify(v))
        this.initialVideos[0].name = '视频.mp4'
        console.log(this.initialVideos)
      }
    }
  },

  computed: {
    disabledUpload () {
      return this.limit && this.videoCount === this.limit
    },
    styleRo () {
      let str = 'rotate(' + this.rt + 'deg)'
      return {
        'transform': str,
        '-ms-transform': str,
        '-moz-transform': str,
        '-webkit-transform': str,
        '-o-transform': str
      }
    }
  },

  methods: {
    rotota () {
      if (this.rt >= 270) {
        this.rt = 0
      } else {
        this.rt += 90
      }
    },
    handleSuccess (res) {
      this.initialVideos.push({
        id: Math.random(),
        url: res.datas,
        createTime: dayjs().format('YYYY-MM-DD')
      })
      this.videoCount++
      this.$emit('add', res.datas)
    },

    handleRemoveAttachment (file) {
      this.videoCount--
      if (file.response) {
        this.initialVideos.splice(this.initialVideos.findIndex(i => i.url === file.response.datas), 1)
        this.$emit('remove', file.response.datas)
      } else {
        this.initialVideos.splice(this.initialVideos.findIndex(i => i.url === file.url), 1)
        this.$emit('remove', file.url.substring('/files'.length))
      }
    },

    handlePreviewAttachment (file) {
      this.dialogVideoUrl = file.url
      this.dialogVisible = true
    }
  },
  mounted () {
    this.header.token = this.$cookie.get('token')
  }
}

</script>

<style lang="scss" scoped>
.date-row {
  display: flex;
  .date-info {
    text-align: center;
    width: 148px;
    margin-right: 8px;
  }
}
</style>
