<template>
  <form-view
    :url="`/repair/${!dataForm.id ? 'save' : 'updateById'}`"
    :data-form="dataForm"
    v-loading="loading"
    :custom-validator="customValidator"
    :before-submit="beforeSubmit"
  >
    <el-row :gutter="25">
      <el-col :span="6">
        <el-form-item label="自编号" prop="carId">
          <el-select  filterable v-model="dataForm.carId" @change="handleChangeCar">
            <el-option v-for="s in carList" :key="s.id" :label="s.number" :value="s.id"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="所属基地" prop="baseId">
          <el-select filterable disabled v-model="dataForm.baseId">
            <el-option v-for="item in baseList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="车牌号码" prop="licensePlate">
          <el-input disabled v-model="dataForm.licensePlate"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="车辆型号" prop="carSize">
          <el-input disabled v-model="dataForm.carSize"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="25">
      <el-col :span="6">
        <el-form-item label="维修类型" prop="type">
          <el-select filterable v-model="dataForm.type">
            <el-option label="工地故障快修" :value="1"></el-option>
            <el-option label="预约维修" :value="2"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="维修时间" prop="repairTime">
          <el-date-picker v-model="dataForm.repairTime"></el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="审核人" prop="verifyUserId">
          <el-select disabled v-model="dataForm.verifyUserId">
            <el-option v-for="s in veirfyUserList" :key="s.id" :label="s.nickName" :value="s.id"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label-width="82px" label="维修工程师" prop="repairUserId">
          <el-select filterable v-model="dataForm.repairUserId">
            <el-option v-for="s in veirfyUserList" :key="s.id" :label="s.nickName" :value="s.id"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="25">
      <el-col :span="12">
        <el-form-item label="维修地址" prop="address">
          <el-input v-model="dataForm.address"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="故障描述" prop="faultNote">
          <el-input type="textarea" v-model="dataForm.faultNote"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row style="margin-bottom:20px;">
      <video-uploader
        @add="addVideo"
        @remove="removeVideo"
        :videos="dataForm.images || []"
        no-title
      ></video-uploader>
    </el-row>
    <div class="section-title-row" v-if="id">
      <div class="title">操作历史</div>
      <div class="line"></div>
    </div>
    <div class="record-area" v-if="id">
      <div class="record-row" v-for="(r, i) in records" :key="i">{{r}}</div>
    </div>
  </form-view>
</template>

<script>
import FormView from '@/components/templates/form-view'
import VideoUploader from '@/components/video-uploader'
import dayjs from 'dayjs'
import { operationTypes } from '@/utils/constants'

export default {
  name: 'car-repair-update',

  components: { FormView, VideoUploader },

  data () {
    return {
      loading: false,
      dataForm: {},
      carList: [],
      baseList: [],
      records: [],
      id: null,
      statusList: [],
      veirfyUserList: [],
      repairUserList: []
    }
  },

  created () {
    this.init()
  },

  methods: {
    async init () {
      const id = this.id = this.$route.query.id
      this.loading = true
      if (id) {
        const data = await this.$http({
          url: this.$http.adornUrl('/repair/getById'),
          method: 'post',
          data: id
        })
        if (data && data.code === 200) {
          this.dataForm = data.datas
          this.dataForm.testsType = parseInt(this.dataForm.testsType)
          this.dataForm.testsDate = dayjs(this.dataForm.testsDate).valueOf()
          if (this.dataForm.enclosure) {
            this.dataForm.images = this.dataForm.enclosure.split(',').map(j => ({ name: j.substring(j.lastIndexOf('/') + 1), url: '/files' + j }))
            this.dataForm.imageList = this.dataForm.images.map(j => j.url.substring('/files'.length))
          } else {
            this.dataForm.images = []
            this.dataForm.imageList = []
          }
          this.records = data.datas.operationList
          this.getUsers(this.dataForm.baseId)
          this.loading = false
        }
      } else {
        this.dataForm = {
          carId: '',
          baseId: '',
          licensePlate: '',
          carSize: '',
          type: '',
          repairTime: '',
          verifyUserId: '',
          repairUserId: '',
          address: '',
          faultNote: '',
          imageList: []
        }
        this.loading = false
      }
      let data = await this.$http({
        url: this.$http.adornUrl('/car/listnormal'),
        method: 'post',
        data: {}
      })
      this.carList = data.datas
      data = await this.$http({
        url: this.$http.adornUrl('/user/base/list'),
        method: 'post'
      })
      this.baseList = data.datas
      data = await this.$http({
        url: this.$http.adornUrl('/common/repairStatusList'),
        method: 'post'
      })
      this.statusList = data.datas
    },

    handleChangeCar (carID) {
      const target = this.carList.find(c => c.id === carID)
      this.dataForm.licensePlate = target.licensePlate
      this.dataForm.carSize = target.carSize
      this.dataForm.baseId = target.baseId
      if (target.baseId) {
        this.getUsers(target.baseId)
      } else {
        this.veirfyUserList = []
        this.repairUserList = []
      }
    },

    async getUsers (baseId) {
      let data = await this.$http({
        url: this.$http.adornUrl('/repair/verifyUserList'),
        method: 'post',
        data: baseId
      })
      this.veirfyUserList = data.datas
      data = await this.$http({
        url: this.$http.adornUrl('/repair/repairUserList'),
        method: 'post',
        data: baseId
      })
      this.repairUserList = data.datas
    },

    addVideo (path) {
      this.dataForm.imageList.push(path)
    },

    removeVideo (url) {
      this.dataForm.imageList.splice(this.dataForm.imageList.indexOf(url), 1)
    },

    customValidator () {
      return true
    },

    beforeSubmit (params) {
      params.enclosure = params.imageList.join(',')
      return params
    }
  }
}
</script>
